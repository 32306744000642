export const helpToggle = (function(){

	let my = {};

	function _helpToggle() {
		const helpItems = document.querySelectorAll('#help .help_item_wrapper');
		helpItems.forEach(helpItem => {
			helpItem.addEventListener('click', function(e){
				let clickedItem = e.currentTarget;
				clickedItem.toggleAttribute('open');
			});
		});
        
		setTimeout(() => {
			helpItems[0].click();
		}, "500");
	}

	my.init = function(){
		_helpToggle();
	};

	return my;
})();

export const helpSticky = (function(){

	let my = {};

	function _helpSticky() {
		const contentPage = document.querySelector('.help_page_wrapper');
		const ctaContainer = document.querySelector('.help_page_sidebar');
		
		window.addEventListener('scroll', () => {
			
			if(window.outerWidth > 950 && contentPage && ctaContainer) {
				let displayOffset = 0;
				let x = window.scrollY - contentPage.offsetTop - displayOffset;
				let min = 0;
				let max = contentPage.offsetHeight - ctaContainer.offsetHeight;
				if(x < min) x = min;
				if(x > max) x = max;
				ctaContainer.style.top = x + 'px';
			}
		});
	}

	my.init = function(){
		_helpSticky();
	};

	return my;
})();