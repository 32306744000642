/* global google */
import { getMapsHelper } from './util/mapsHelper.js';

const locationsMap = {

	initialized: false,

	theMap: {
		ele: document.querySelector('#locations_map'),
		instance: null
	},
	startMap: async function() {
		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();

		this.theMap.instance = mapsHelper.createMap({
			element: this.theMap.ele,
			locationElementSelector: '.map_source',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `<a href="${el.getAttribute('link')}" class="map_pin map_source"><img src="/dist/images/icons/map_pin.svg" alt="" /><span class="map_pin_count">${el.getAttribute('count')}</span></a>`
				};
			}
		});

		google.maps.event.addListenerOnce(this.theMap.instance, 'idle', () => {
			if (document.querySelectorAll('.map_source').length < 2) {
				this.theMap.instance.setZoom(12);
			}
		});
	},
	mapInView: function(){
		const rect = document.querySelector('#locations_map').getBoundingClientRect();
		return (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight));
	},
	init: function(){
		if(this.mapInView()){
			this.startMap();
		}else{
			this.startMap();{
				const rect = document.querySelector('#locations_map').getBoundingClientRect();
				const isInView = (rect.top >= 0 && rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) + 1000));
				if(!this.initialized && isInView){
					locationsMap.startMap();
					this.initialized = true;
				}
			}
		}
	}
};


export const initlocationsMap = () => locationsMap.init();