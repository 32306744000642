export const sizeTools = (function(){
	let my = {};

	function _sizeListToggle() {
		const sizeHeadings = document.querySelectorAll('#size_list .facility_card_mobile_heading');

		sizeHeadings.forEach(sizeHeading => {
			sizeHeading.addEventListener('click', function(e){
				let clickedHeading = e.currentTarget;
				clickedHeading.toggleAttribute('open');
				let clickedContainer = e.currentTarget.parentNode;
				clickedContainer.toggleAttribute('open');
			});
		});
	}

	my.init = function(){
		_sizeListToggle();
	};

	return my;
})();