import {
	AutomatitCarousel
} from './automatit_carousel.m';

import {
	getMapsHelper
} from './util/mapsHelper';

export const facilityCaro = (function () {
	let my = {};

	my.init = function () {
		if (document.getElementById('facility_carousel')) {

			if (window.slide_images.length > 1) {
				const options = {
					element: document.getElementById('facility_carousel'),
					speed: 600,
					images: window.slide_images,
					intervalTiming: 6000,
					imagesAsBackgrounds: true,
					showDots: false,
					useCheverons: true
				};
				AutomatitCarousel(options);
			} else {
				let btns = document.getElementById('facility_carousel_chevrons');
				btns.parentNode.removeChild(btns);
				const options = {
					element: document.getElementById('facility_carousel'),
					speed: 600,
					images: window.slide_images,
					imagesAsBackgrounds: true,
					intervalTiming: 0,
					showDots: false
				};
				AutomatitCarousel(options);
			}
		}
	};

	return my;
})();

/* eslint-disable no-undef */
export const locationMap = (function(){
	let my = {},
		map = document.getElementById('facility_map');

	my.init = function(){
		if(document.getElementById('facility_map')){
			const mapHelper = getMapsHelper();
			mapHelper.ready()
				.then(() => {
					const theMap = mapHelper.createMap({
						element: map,
						locationElementSelector: '#facility_map',
						useRichmarker: true,
						markerReducer: el => {
							return {
								lat: el.getAttribute('lat'),
								lng: el.getAttribute('lng'),
								content: `
                                <a class="map_pin" href="${el.getAttribute('link')}" target="_blank">
                                    <img src="/dist/images/icons/map_pin.svg" alt="Visit facility" />
                                </a>
                                `
							};
						}
					});

					google.maps.event.addListenerOnce(theMap, 'idle', () => {
						theMap.setZoom(15);
					});
				});
		}
	};

	return my;
})();

export const mapCaroToggle = (function(){

	let my = {};

	function _mapCaroToggle() {
		const toggleButtons = document.querySelectorAll('.fac_controls button');
		const removeAttribute= (arrayitem) => {
			arrayitem.forEach(x => {
				x.removeAttribute('active');
			});
		};

		toggleButtons.forEach(toggleButton => {

			toggleButton.addEventListener('click', function(e){
				removeAttribute(toggleButtons);

				let clickedItem = e.currentTarget;
				clickedItem.toggleAttribute('active');

				if(clickedItem.getAttribute('data-toggle') === "map"){
					document.getElementById('facility_map').setAttribute('active', true);
				}
				if(clickedItem.getAttribute('data-toggle') === "gallery"){
					document.getElementById('facility_map').removeAttribute('active');
				}
			});
		});
	}

	my.init = function(){
		_mapCaroToggle();
	};

	return my;
})();

export const filters = (function(){
	let my = {};

	function _filterUnits(e){
		var unitShown = 0;
		const noUnits = document.querySelector('#no_units');
		document.querySelector("#facility_rates_filter .active").classList.remove('active');
		e.currentTarget.classList.add('active');

		let size = e.currentTarget.dataset.size;
		[].forEach.call(document.querySelectorAll('#facility_rates .unit_row'), (row) => {
			row.style.display = 'none';
			if(size === "all" || row.dataset.props.includes(size)){
				row.style.display = 'flex';
				unitShown++;

			}
			if(unitShown === 0){
				noUnits.style.display = 'block';
			}else {
				noUnits.style.display = 'none';
			}
		});
	}

	function _assignListeners(){
		[].forEach.call(document.querySelectorAll('#facility_rates_filter .rates_filter'), (filter) => {
			filter.addEventListener('click', _filterUnits);
		});
	}

	my.init = function(){
		if(document.getElementById('facility_rates_filter')){
			_assignListeners();
		}
	};

	return my;
})();

export const hoursToggle = (function(){

	let my = {};

	function _hoursToggle() {

		const hoursToggleButton = document.querySelector('#facility_info_caro .fac_info_hours_heading');
		const hoursContainer = document.querySelector('#facility_info_caro .fac_hours');

		

		hoursToggleButton.addEventListener('click', function(e){

			let hoursToggle = e.currentTarget;
			hoursToggle.toggleAttribute('active');
			hoursContainer.toggleAttribute('active');
		});
	}

	my.init = function(){
		_hoursToggle();
	};

	return my;
})();

export const featuresToggle = (function(){

	let my = {};

	function _featuresToggle() {

		const featToggleButton = document.querySelector('#facility_features h2.mobile');
		const featContainer = document.querySelector('#facility_features ul');

		

		featToggleButton.addEventListener('click', function(e){

			let featButton = e.currentTarget;
			console.log(featButton);
			featButton.toggleAttribute('active');
			featContainer.toggleAttribute('active');
		});
	}

	my.init = function(){
		_featuresToggle();
	};

	return my;
})();

export const locationSelect = (function(){

	let my = {};

	function _locationSelect() {
		
		let filterParamater = window.location.search;
		let filterString = filterParamater.split('?facility=');
		let filterLocation = filterString[1];
		const selectOptions = document.querySelectorAll('#facility_input option');
		selectOptions.forEach(selectOption => {
			if(selectOption.value === filterLocation){
				selectOption.selected = 'selected';
			}
		});
	}

	my.init = function(){
		_locationSelect();
	};

	return my;
})();