import {
	AutomatitCarousel
} from './automatit_carousel.m';

export const homeModal = (function(){

	let my = {};

	function _homeModal() {
		//Function to toggle modal state
		function toggleModal(){
			document.getElementById('homepage_modal').toggleAttribute('open');
		}

		//Modal Close Button
		if(document.getElementById('close_modal_btn')){
			document.getElementById('close_modal_btn').addEventListener('click', function() {
				toggleModal();
			});
		}

		//Close Modal if clicking outside inner section
		if(document.getElementById('homepage_modal')){
			document.getElementById('homepage_modal').addEventListener('click', function(e) {
				let clickedItem = e.target.closest('div');
				if(clickedItem.getAttribute('data-container') && clickedItem.getAttribute('data-container') === 'modal_container'){
					toggleModal();
				}
			});
		}
        
		setTimeout(() => {
			toggleModal();
		}, "500");
	}

	my.init = function(){
		_homeModal();
	};

	return my;
})();

export const homeLocationToggle = (function(){

	let my = {};

	function _homeLocationToggle() {
		const locationTextToggles = document.querySelectorAll('#home_location_info h3');
		const locationTextItems = document.querySelectorAll('#home_location_info .home_location_info_item');

		const removeAttribute = (arrayitem) => {
			arrayitem.forEach(x => {
				x.removeAttribute('open');
			});
		};

		locationTextToggles.forEach(locationTextToggle => {
			locationTextToggle.addEventListener('click', function(e){
				let clickedItem = e.currentTarget;
				let clickedSection = e.currentTarget.parentNode;
				
				if(clickedItem.hasAttribute('open')){
					clickedItem.removeAttribute('open');
					clickedSection.removeAttribute('open');
				} else {
					removeAttribute(locationTextToggles);
					removeAttribute(locationTextItems);
					clickedItem.toggleAttribute('open');
					clickedSection.toggleAttribute('open');
				}
			});
		});
        
		locationTextToggles[0].click();
	}

	my.init = function(){
		_homeLocationToggle();
	};

	return my;
})();

export const homeCaroTabs = (function(){

	let my = {};

	function _homeCaroTabs() {
		const tabs = document.querySelectorAll('#home_location_info .tabs button');
		const caros = document.querySelectorAll('#home_location_info .home_location_info_caro_wrapper .caro');

		const removeClass= (arrayitem) => {
			arrayitem.forEach(x => {
				x.classList.remove('active');
			});
		};

		tabs.forEach(tab => {
			tab.addEventListener('click', function(e){
				removeClass(tabs);
				removeClass(caros);
				let clickedTab = e.currentTarget;
				if(clickedTab.classList.contains('active') === false){
					clickedTab.classList.add('active');
				}
				caros.forEach(caro => {
					if(caro.getAttribute('data-tab') === clickedTab.getAttribute('data-tab')){
						caro.classList.add('active');
					}
				});
			});
		});
        
		tabs[0].click();
	}

	my.init = function(){
		_homeCaroTabs();
	};

	return my;
})();

export const homepageCaro = (function () {
	let my = {};

	my.init = function () {
		if (document.getElementById('home_beechgrove_caro')) {
			const options = {
				element: document.getElementById('home_beechgrove_caro'),
				speed: 600,
				images: window.beechgroveImgs,
				imagesAsBackgrounds: true,
				intervalTiming: 0,
				showDots: false
			};
			AutomatitCarousel(options);
		}
		if (document.getElementById('home_buttermilk_caro')) {
			const options = {
				element: document.getElementById('home_buttermilk_caro'),
				speed: 600,
				images: window.buttermilkImgs,
				imagesAsBackgrounds: true,
				intervalTiming: 0,
				showDots: false
			};
			AutomatitCarousel(options);
		}
	};

	return my;
})();