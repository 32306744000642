import { contactSubmit } from './contact.js';
import { navToggle } from './header.js';
import { homeModal, homeLocationToggle, homeCaroTabs, homepageCaro } from './home.js';
import { facilityCaro, locationMap, mapCaroToggle, hoursToggle, filters, featuresToggle, locationSelect } from './facility.js';
import { reviews } from './reviews.js';
import { sizeTools } from './tools.js';
import { helpToggle, helpSticky } from './help.js';
import { initlocationsMap } from './locations_map.js';

navToggle.init();
sizeTools.init();

if(document.getElementById('homepage_modal')){
	homeModal.init();
}

if(document.getElementById('home_location_info')){
	homeLocationToggle.init();
	homeCaroTabs.init();
	homepageCaro.init();
}

if(document.getElementById('facility_caro_map')){
	mapCaroToggle.init();
}

if(document.getElementById('facility_info_caro')){
	hoursToggle.init();
}

if(document.getElementById('facility_carousel')){
	facilityCaro.init();
}

if(document.getElementById('facility_map')){
	locationMap.init();
}

if(document.getElementById('facility_rates_filter')){
	filters.init();
}

if(document.getElementById('facility_features')){
	featuresToggle.init();
}

if(document.getElementById('reviews_caro')){
	reviews.init();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if(document.querySelector('.help_page_content')){
	helpToggle.init();
	helpSticky.init();
}

if(document.querySelector('#locations_map')) {
	initlocationsMap();
}

if(document.getElementById('facility_input') && window.location.search.includes('?facility=')) {
	locationSelect.init();
}
